// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-angela-js": () => import("./../src/pages/about-angela.js" /* webpackChunkName: "component---src-pages-about-angela-js" */),
  "component---src-pages-about-bcst-js": () => import("./../src/pages/about-bcst.js" /* webpackChunkName: "component---src-pages-about-bcst-js" */),
  "component---src-pages-animal-wellness-js": () => import("./../src/pages/animal-wellness.js" /* webpackChunkName: "component---src-pages-animal-wellness-js" */),
  "component---src-pages-appointments-js": () => import("./../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-benefits-of-bcst-js": () => import("./../src/pages/benefits-of-bcst.js" /* webpackChunkName: "component---src-pages-benefits-of-bcst-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-womens-health-and-children-js": () => import("./../src/pages/womens-health-and-children.js" /* webpackChunkName: "component---src-pages-womens-health-and-children-js" */)
}

